import OrganizationClient from './client/organization';
import MainClient from './client/main';
import UserClient from './client/user';
import BrandProfileClient from './client/brandprofile';

/* eslint-disable dot-notation */

const clientMap = {};
const client = {
    organization(id) {
        const idx = `organization_${id}`;
        if (!clientMap[idx]) {
            clientMap[idx] = new OrganizationClient({ endpoint: '/', serviceEndpoint: '/api/browser', organizationId: id });
        }
        return clientMap[idx];
    },
    main() {
        if (!clientMap['main']) {
            clientMap['main'] = new MainClient({ endpoint: '/', serviceEndpoint: '/api/browser' });
        }
        return clientMap['main'];
    },
    user(id) {
        const idx = `user_${id}`;
        if (!clientMap[idx]) {
            clientMap[idx] = new UserClient({ endpoint: '/', serviceEndpoint: '/api/browser', userId: id });
        }
        return clientMap[idx];
    },
    brandprofile() {
        if (!clientMap['brandprofile']) {
            clientMap['brandprofile'] = new BrandProfileClient({ endpoint: '/', serviceEndpoint: '/api/browser' });
        }
        return clientMap['brandprofile'];
    },
};

export default client;
