import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/**
 * The 'layout' meta parameter indicates the page layout to use for that view; see plugins/layouts.js for options.
 * Authentication is required by default for all views. The `isPublic` meta parameter indicates that a view
 * is public and does not require authentication to display.
 */
const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        {
            path: '/',
            name: 'front',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "front" */ '../views/Front.vue'),
        },
        {
            path: '/verify-email',
            name: 'verify-email',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "verify-email" */ '../views/VerifyEmail.vue'),
        },
        {
            path: '/prompt',
            name: 'prompt',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "prompt" */ '../views/Prompt.vue'),
        },
        {
            path: '/signup',
            name: 'signup',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "signup" */ '../views/Signup.vue'),
        },
        {
            path: '/login',
            name: 'login',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        },
        {
            path: '/interaction',
            name: 'interaction',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "interaction" */ '../views/Interaction.vue'),
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
        },
        {
            path: '/settings',
            name: 'settings',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "settings" */ '../views/settings/Index.vue'),
        },
        {
            path: '/settings/session',
            name: 'settings-session',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "settings-session" */ '../views/settings/Session.vue'),
        },
        {
            path: '/profile',
            name: 'profile',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Index.vue'),
        },
        {
            path: '/profile/authn/loginfront',
            name: 'profile-settings-authn-loginfront',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authn-loginfront" */ '../views/profile/authn/LoginFront.vue'),
        },
        {
            path: '/profile/authz',
            name: 'profile-settings-authz',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authz" */ '../views/profile/Authz.vue'),
        },
        {
            path: '/service',
            name: 'service-admin',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin" */ '../views/service/Index.vue'),
        },
        {
            path: '/service/setup',
            name: 'service-admin-setup',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin-setup" */ '../views/service/Setup.vue'),
        },
        {
            path: '/service/settings',
            name: 'service-admin-settings',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin-settings" */ '../views/service/Settings.vue'),
        },
        // {
        //     path: '/service/c2',
        //     name: 'service-admin-c2',
        //     meta: { layout: 'admin-layout' },
        //     component: () => import(/* webpackChunkName: "service-admin-c2" */ '../views/service/C2.vue'),
        // },
        // {
        //     path: '/service/dns',
        //     name: 'service-admin-dns',
        //     meta: { layout: 'admin-layout' },
        //     component: () => import(/* webpackChunkName: "service-admin-dns" */ '../views/service/Dns.vue'),
        // },
        // {
        //     path: '/service/dynamic-shared-domain',
        //     name: 'service-admin-dynamic-shared-domain',
        //     meta: { layout: 'admin-layout' },
        //     component: () => import(/* webpackChunkName: "service-admin-dynamic-shared-domain" */ '../views/service/DynamicSharedDomain.vue'),
        // },
        {
            path: '/user/organization-list',
            name: 'user-organization-list',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-organization-list" */ '../views/user/OrganizationList.vue'),
        },
        {
            path: '/user/create/organization',
            name: 'user-create-organization',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-create-organization" */ '../views/user/CreateOrganization.vue'),
        },
        {
            path: '/search',
            name: 'search',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
        },
        {
            path: '/organization/:organizationId',
            name: 'organization-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-dashboard" */ '../views/organization/Dashboard.vue'),
        },
        {
            path: '/organization/:organizationId/delete',
            name: 'organization-delete',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-delete" */ '../views/organization/Delete.vue'),
        },
        {
            path: '/organization/:organizationId/create/client-token',
            name: 'organization-create-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-create-client-token" */ '../views/organization/CreateClientToken.vue'),
        },
        {
            path: '/organization/:organizationId/create/product',
            name: 'organization-create-product',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-create-product" */ '../views/organization/CreateProduct.vue'),
        },
        {
            path: '/organization/:organizationId/edit/brandprofile',
            name: 'organization-edit-brandprofile',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-brandprofile" */ '../views/organization/EditBrandProfile.vue'),
        },
        {
            path: '/organization/:organizationId/edit/hostname',
            name: 'organization-edit-hostname',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-hostname" */ '../views/organization/EditHostname.vue'),
        },
        {
            path: '/organization/:organizationId/edit/stripe',
            name: 'organization-edit-stripe',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-stripe" */ '../views/organization/EditStripe.vue'),
        },
        {
            path: '/organization/:organizationId/edit/product',
            name: 'organization-edit-product',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-product" */ '../views/organization/EditProduct.vue'),
        },
        {
            path: '/organization/:organizationId/search/account',
            name: 'organization-search-account',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-account" */ '../views/organization/SearchAccount.vue'),
        },
        {
            path: '/organization/:organizationId/search/client-token',
            name: 'organization-search-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-client-token" */ '../views/organization/SearchClientToken.vue'),
        },
        {
            path: '/organization/:organizationId/search/product',
            name: 'organization-search-product',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-product" */ '../views/organization/SearchProduct.vue'),
        },
        {
            path: '/organization/:organizationId/search/user',
            name: 'organization-search-user',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-user" */ '../views/organization/SearchUser.vue'),
        },
        // {
        //     path: '/organization/:organizationId/product/:productId',
        //     name: 'organization-view-product',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "organization-view-product" */ '../views/organization/ViewProduct.vue'),
        // },
        {
            path: '/organization/:organizationId/user/:userId',
            name: 'organization-view-user',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-view-user" */ '../views/organization/ViewUser.vue'),
        },
        // {
        //     path: '/organization/:organizationId/product/:productId/delete',
        //     name: 'organization-delete-product',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "organization-delete-product" */ '../views/organization/DeleteProduct.vue'),
        // },
        {
            path: '/webauthz/grant',
            name: 'webauthz-grant',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "webauthz-grant" */ '../views/webauthz/Grant.vue'),
        },
        {
            path: '/webauthz/prompt',
            name: 'webauthz-prompt',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "webauthz-prompt" */ '../views/webauthz/Prompt.vue'),
        },
//////////////////////////
/////////
//////////////////////////
/////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
//////////
/////////////////
        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
