import Vue from 'vue';
import Vuex from 'vuex';
import { toMillis } from '@libertyio/time-util-js';
import client from '../sdk/client-factory';

const MAX_REFRESH_MILLIS = toMillis({ days: 1 });

Vue.use(Vuex);

/*
Do not redirect user from here (no $router.push). Respond to calling code
with appropriate indicators and let the calling code redirect as needed.
*/

export default new Vuex.Store({
    state: {
        focus: null,
        brand: null,
        palette: null,
        /*
        We use a default palette with greys to minimize the flicker that
        happens when the actual brand palette is finally loaded; if we
        use Emplus brand colors, the flicker is very noticeable
        */
        defaultPalette: {
            text: '#000000', // black
            primary: '#9E9E9E', // grey
            primaryText: '#ffffff', // white; text color that should be used when text is over the primary color
            secondary: '#E0E0E0', // grey lighten-2
            accent: '#B0BEC5', // blue-grey lighten-3
            background: '#F5F5F5', // grey lighten-4
        },
        organizationId: null, // organization id, set by App.vue for /org routes
        isReady: false, // indicates that we loaded session info from server, so we know if user is authenticated; and if user is authenticated, that we've also loaded user info and organization info from server
        serviceInfo: {}, // registration_mode, stripeTokenPublicKey
        // serviceVersion: {}, // version
        session: { isAuthenticated: false }, // userId, isAuthenticated, notAfter, isCsrfGuardEnabled
        user: {}, // name, email, sessionIdleExpiresMillis
        interactionMap: {}, // id => interaction ( type: string, next: string, state: object )
        nav: { queue: [] }, // queue  with items pushed to it, so whenever we are done with something and want to know where to return to, we pop the last item from the queue and go there; and each item can have a function to determine if it's still valid (and the user should be directed there) or if it should be ignored (remove from the queue and proceed to next item)
        loadingMap: { init: true },
        // noteList: [],
        productTypeChoices: [
            // physical items to ship to a customer, or to be picked up by a customer
            // { text: 'Artwork', value: 'artwork' },
            { text: 'Bundle', value: 'bundle' },
            // { text: 'Unique', value: 'unique' }, // for one-of-a-kind items such as original art, autographed items, etc. so it's flat pricing only (because there is only one) and it's one-time only (not recurring -- if customer needs financing that would be a separate feature); and also we automatically have a stock of 1, so when it's purchased it will not be available to other customers
            { text: 'Clothing', value: 'clothing' },
            { text: 'Donation', value: 'donation' }, // flat price, one-time or recurring
            { text: 'Download', value: 'download' }, // digital download such as e-book, or software
            // { text: 'Electronics', value: 'electronics' },
            { text: 'Membership', value: 'membership' },
            { text: 'Merchandise', value: 'merchandise' },
            // { text: 'Motor Vehicle', value: 'motor-vehicle' }, // regulations may apply
            { text: 'SaaS', value: 'saas' }, // a subscription for use of an online service; typically the service stores customer data, so unlike a magazine subscription where you can cancel and don't need anything else from them, when you cancel a saas you might want to export your data first or migrate to another provider
            { text: 'Subscription', value: 'subscription' }, // subscription to physical item shipment or digital item download or service access
            { text: 'Ticket', value: 'ticket' },
            // temporary use of physical items
            // { text: 'Rental', value: 'rental' }, // short-term rental of a physical item, vehicle, or space
            // { text: 'Lease', value: 'lease' }, // long-term lease agreement for a physical item, vehicle, or space
        ],
        productPublishedChoices: [
            { text: 'Published', value: true },
            { text: 'Draft', value: false },
        ],
        priceBillingMethodProperties: {
            flat: {
                quantity: false,
                licensed: true,
            },
            custom: {
                quantity: false,
                licensed: true,
                custom: true,
            },
            unit: {
                quantity: true,
                licensed: true,
                metered: true,
            },
            volume: {
                quantity: true,
                licensed: true,
                metered: true,
            },
            stacked: {
                quantity: true,
                licensed: true,
                metered: true,
            },
            package: {
                quantity: true,
                licensed: true,
                metered: true,
            },
        },
    },
    getters: {
        isLoading(state) {
            return Object.values(state.loadingMap).reduce((acc, item) => acc || item, false);
        },
        isAuthenticated(state) {
            return state.session.isAuthenticated ?? false;
        },
        brandName(state) {
            return state.brand?.name ?? ''; // 'Loading...';
        },
        primaryColor(state) {
            let result;
            if (Array.isArray(state.palette?.content?.primary) && state.palette.content.primary.length > 0) {
                result = state.palette.content.primary[0].hex;
            } else {
                result = state.defaultPalette.primary;
            }
            // TODO: input validation that palette primary color is a valid hex color value or html color name
            return result;
        },
        primaryTextColor(state) {
            return state.defaultPalette.primaryText;
        },
        accentColor(state) {
            let result;
            if (Array.isArray(state.palette?.content?.accent) && state.palette.content.accent.length > 0) {
                result = state.palette.content.accent[0].hex;
            } else {
                result = state.defaultPalette.accent;
            }
            // TODO: input validation that palette accent color is a valid hex color value or html color name
            return result;
        },
        cardTitleBarTextStyle(state, getters) {
            return `color: ${getters.primaryTextColor}`;
        },
        cardTitleBarStyle(state, getters) {
            return `background-color: ${getters.primaryColor}`;
        },
        primaryButtonStyle(state, getters) {
            return `color: ${getters.primaryTextColor}; background-color: ${getters.primaryColor};`;
        },
        primaryIconStyle(state, getters) {
            return `color: ${getters.primaryColor};`;
        },
    },
    mutations: {
        ready(state) {
            console.log('vuex store: ready');
            state.isReady = true;
        },
        focus(state, value) {
            state.focus = value;
        },
        brand(state, brand) {
            state.brand = brand;
        },
        palette(state, palette) {
            state.palette = palette;
        },
        setServiceInfo(state, serviceInfo) {
            state.serviceInfo = serviceInfo;
        },
        setSession(state, session) {
            state.session = session;
        },
        setUser(state, user) {
            state.user = user;
        },
        setInteraction(state, interaction) {
            state.interactionMap = { ...state.interactionMap, ...interaction };
        },
        setNav(state, nav) {
            state.nav = nav;
        },
        loading(state, progress) {
            state.loadingMap = { ...state.loadingMap, ...progress };
        },
        // noteList(state, values) {
        //     state.noteList = [...values];
        // },
    },
    actions: {
        // async createOrganization({ commit, dispatch, state }, organizationInfo) {
        //     commit('loading', { createOrganization: true });
        //     const response = await client.user.create(organizationInfo);
        //     if (response.isCreated) {
        //         await dispatch('loadSession');
        //         if (state.session.isAuthenticated) {
        //             await dispatch('loadUser');
        //             // await dispatch('loadOrganization');
        //         }
        //     }
        //     commit('loading', { createOrganization: false });
        //     return response;
        // },
        async logout({ commit }) {
            commit('loading', { logout: true });
            await client.main().authn.logout();
            // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
            commit('setSession', { isAuthenticated: false });
            commit('loading', { logout: false });
        },
        // async enableCsrfGuard({ commit, state }) {
        //     const csrfTokenResponse = await client.main().authn.createCsrfToken();
        //     if (csrfTokenResponse.token) {
        //         const csrfGuardToken = csrfTokenResponse.token;
        //         localStorage.setItem('csrfGuardToken', csrfGuardToken);
        //         commit('setSession', { ...state.session, isCsrfGuardEnabled: true, csrfGuardToken });
        //     }
        // },
        async init({ commit, dispatch, state }, { force = false } = {}) {
            if (state.isReady && !force) {
                console.log('vuex store: init already done');
                return;
            }
            console.log('vuex store: init');
            commit('loading', { init: true });
            try {
                await Promise.all([
                    dispatch('loadServiceInfo'),
                    dispatch('loadSession'),
                ]);
                console.log('vuex store: loaded service info and session');
                /*
                if (state.session.isCsrfGuardEnabled && state.session.csrfGuardToken) {
                    // csrf guard enabled, store the token for use by loginshiedl client
                    localStorage.setItem('csrfGuardToken', state.session.csrfGuardToken);
                } else {
                    // enable csrf guard
                    await dispatch('enableCsrfGuard');
                }
                */
                if (state.session.isAuthenticated) {
                    // load data concurrently
                    await Promise.all([
                        dispatch('loadUser'),
                    ]);
                }
                console.log('vuex store: ready');
                commit('ready');
            } catch (err) {
                console.error('vuex store: init failed');
            }
            commit('loading', { init: false });
        },
        async refresh({ dispatch, state }) {
            console.log('vuex store: refresh');
            // not displaying loading bar because we want this to be transparent
            try {
                await dispatch('loadSession', { progressIndicator: false });
                if (state.session.isAuthenticated) {
                    await dispatch('loadUser', { progressIndicator: false });
                }
            } catch (err) {
                console.error('vuex store: refresh failed');
            }
        },
        async loadServiceInfo({ commit }) {
            commit('loading', { loadServiceInfo: true });
            try {
                const [/* versionInfo, */serviceInfo] = await Promise.all([
                    // client.service.getVersion(),
                    client.main().service.getInfo(),
                ]);
                // commit('setServiceVersion', versionInfo);
                commit('setServiceInfo', serviceInfo);
            } catch (err) {
                console.error('vuex store: failed to load service info');
            }
            commit('loading', { loadServiceInfo: false });
        },
        async loadSession({ commit, dispatch, state }, { progressIndicator = true } = {}) {
            if (progressIndicator) {
                commit('loading', { loadSession: true });
            }
            try {
                const sessionInfo = await client.main().authn.get();
                console.log(`vuex store: session ${JSON.stringify(sessionInfo)}`);
                const now = Date.now();
                const {
                    user_id: userId,
                    authenticated = false,
                    unlocked = false,
                    // duration = null,
                    etag = {},
                    // permit = {}, // only when user is authenticated
                    // antiphishing = {}, // only when user is authenticated
                    antiphishing_not_after: rememberMeNotAfter, // only when user is authenticated
                } = sessionInfo;
                const authenticatedDuration = sessionInfo.authenticated_duration;
                const unlockedDuration = sessionInfo.unlocked_duration;
                let refreshAfterDuration = sessionInfo.refresh_after_duration;
                // setTimeout max value is 2147483647, which is more than 24 days
                // but we should refresh the session at least once a day
                if (!Number.isInteger(refreshAfterDuration) || refreshAfterDuration < 0 || refreshAfterDuration > MAX_REFRESH_MILLIS) {
                    refreshAfterDuration = MAX_REFRESH_MILLIS;
                }
                let { reloadTimeoutId } = state.session;
                if (authenticated && typeof refreshAfterDuration === 'number' && refreshAfterDuration > 0) {
                    // clear a previous timeout, if it exists
                    if (reloadTimeoutId) {
                        console.log(`vuex store: clearing timeout ${reloadTimeoutId}`);
                        clearTimeout(reloadTimeoutId);
                    }
                    console.log(`vuex store: scheduling session reload for ${refreshAfterDuration} ms`);
                    reloadTimeoutId = setTimeout(() => {
                        console.log('vuex store: reloading session');
                        dispatch('loadSession');
                    }, refreshAfterDuration);
                }
                commit('setSession', {
                    userId,
                    isAuthenticated: authenticated,
                    isUnlocked: unlocked,
                    authenticatedNotAfter: typeof authenticatedDuration === 'number' && authenticatedDuration > 0 ? now + authenticatedDuration : null,
                    unlockedNotAfter: typeof unlockedDuration === 'number' && unlockedDuration > 0 ? now + unlockedDuration : null,
                    nextRefresh: typeof refreshAfterDuration === 'number' && refreshAfterDuration > 0 ? now + refreshAfterDuration : null,
                    etag,
                    reloadTimeoutId,
                    rememberMeNotAfter,
                    // permit,
                    // antiphishing,
                });
            } catch (err) {
                commit('setSession', { fault: { type: 'read-failed' } });
            }
            commit('loading', { loadSession: false });
        },
        async loadUser({ commit, state }, { progressIndicator = true } = {}) {
            if (progressIndicator) {
                commit('loading', { loadUser: true });
            }
            try {
                const userInfo = await client.user(state.session.userId).user.get();
                commit('setUser', userInfo);
            } catch (err) {
                commit('setUser', { fault: { type: 'read-failed' } });
            }
            commit('loading', { loadUser: false });
        },
        // async editSession({ commit }, sessionInfo) {
        //     commit('loading', { editSession: true });
        //     let isEdited = false;
        //     try {
        //         const newSessionInfo = await client.main().authn.edit(sessionInfo);
        //         commit('setSession', newSessionInfo);
        //         isEdited = true;
        //     } catch (err) {
        //         console.log('editSession error: %o', err);
        //     }
        //     commit('loading', { editSession: false });
        //     return isEdited;
        // },
        async editCurrentUser({ commit, state }, userInfo) {
            try {
                commit('loading', { editCurrentUser: true });
                const { isEdited } = await client.user(state.session.userId).user.edit(userInfo);
                // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
                const newUserInfo = { ...state.user, info: { ...state.user.info, ...userInfo } };
                commit('setUser', newUserInfo);
                return isEdited;
            } catch (err) {
                console.error('editCurrentUser error', err);
                return false;
            } finally {
                commit('loading', { editCurrentUser: false });
            }
        },
        async createInteraction({ commit }, interaction) {
            commit('loading', { createInteraction: true });
            console.log('store: createInteraction %o', interaction);
            const response = await client.main().interaction.create(interaction);
            if (response.id) {
                commit('setInteraction', { [response.id]: response });
            }
            commit('loading', { createInteraction: false });
            return response;
        },
        async editInteraction({ commit }, { interactionId, message }) {
            commit('loading', { editInteraction: true });
            console.log('store: editInteraction %s', interactionId);
            const response = await client.main().interaction.edit(interactionId, message);
            if (response.id) {
                commit('setInteraction', { [response.id]: response });
            }
            commit('loading', { editInteraction: false });
            return response;
        },
        async loadInteraction({ commit }, interactionId) {
            commit('loading', { loadInteraction: true });
            const response = await client.main().interaction.get(interactionId);
            commit('setInteraction', { [interactionId]: response });
            commit('loading', { loadInteraction: false });
            return response;
        },
        async loadBrand({ commit }, { alias }) {
            try {
                commit('loading', { loadBrand: true });
                const response = await client.brandprofile().brand.get({ alias });
                commit('brand', response);
            } catch (err) {
                console.error('loadBrand failed', err);
                commit('brand', null);
            } finally {
                commit('loading', { loadBrand: false });
            }
        },
        async loadPalette({ commit }, { alias, mode = 'light' }) {
            try {
                commit('loading', { loadPalette: true });
                const match = {
                    alias,
                    mode,
                };
                const response = await client.brandprofile().palette.get(match);
                commit('palette', response);
            } catch (err) {
                console.error('loadPalette failed', err);
                commit('palette', null);
            } finally {
                commit('loading', { loadPalette: false });
            }
        },
        queueNavItem({ commit, state }, item) {
            const queue = [...state.nav.queue];
            queue.push({ path: item.path, query: item.query || {}, hash: item.hash || '' });
            commit('setNav', { queue });
        },
        nextNavItem({ commit, state }) {
            try {
                const queue = [...state.nav.queue];
                if (queue.length > 0) {
                    const next = queue.splice(queue.length - 1, 1);
                    commit('setNav', { queue });
                    return next[0];
                }
                if (state.session.isAuthenticated) {
                    return { path: '/dashboard' };
                }
                return { path: '/' };
            } catch (err) {
                console.log('forward error: %o', err);
                return { path: '/' };
            }
        },
    },
});
